const Intro = () => {
  return (
    <section className="flex w-full justify-center py-10">
      <div className="bg-[#F7F3EB] flex flex-col mx-auto w-[85%] text-center drop-shadow-lg p-6 my-5">
        <h1 className="font-semibold text-4xl md:text-6xl md:mb-6 ">Officiant of Love is the premier Los Angeles Multi-Denominational Minister</h1>
        <p className="text-center text-3xl md:text-5xl pt-2">Helena, the Officiant of Love, is dedicated to creating beautiful moments for any event in your life. Coming from a multi-faith background, Helena specializes in compassionate events celebrating all aspects of faith and togetherness. She has been featured in Martha Stewart Wedding, Style Me Pretty Magazines and the Carats and Cake blog.</p>
      </div>
    </section>
  );
};

export default Intro;
